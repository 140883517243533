import axiosService from "lib/axios";
import { useQuery } from "@tanstack/react-query";

const route = "/logs";
export async function getLogs():Promise<{logs: any}> {
    const { data } = await axiosService.get(route);
    return {
        logs: data.logs !== null && data.logs !== '' && data.logs
    };
}

export function deleteLog (){
    return axiosService.delete(route);
}

export async function getAdminLogsStatus():Promise<any>{
    const { data } = await axiosService.get(`${route}/getlogstatus`);
    return data;
}

export async function setAdminLogsStatus({status, secret}: any):Promise<any>{
    return await axiosService.post(`${route}/setlogstatus?status=${!status}&secret=${secret}`);
}