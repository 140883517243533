import Loading from 'components/ui/Loading';
import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query';
import { getAdminLogsStatus, setAdminLogsStatus } from "services/logs";
import { useAppContext } from 'lib/contextLib';
import Switch from '@mui/material/Switch';
import { AppBar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputLabel, TextField, Toolbar, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LogoutUsers } from 'services/user';
import { rowsPerFetch, drawerWidth } from "../../constants";
import theme from "theme";
import { useNavigate } from 'react-router-dom';

const LogsStatus = () => {

  const { setSnackbarState } = useAppContext();
  const [isLoadingData, setIsLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [delSecret, setDelSecretKey] = useState<any>();
  
  const navigate = useNavigate();
  
  useEffect(() => {
    fetchData();
  }, [])
  
  const fetchData = async() => {
    await getAdminLogsStatus().then((data: any) => {
      const dataStatus = JSON.parse(data.status);
      setStatus(dataStatus.is_enable == "true" ? true : false);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const { isError, error, isLoading, mutateAsync } = useMutation(setAdminLogsStatus, {
    onSuccess: ({ data }) => {
      if(data && !data.error){
        const dataStatus = JSON.parse(data.status);
        setStatus(dataStatus.is_enable == "true" ? true : false);
        setOpen(false);
        setSnackbarState({
          open: true,
          message: "Logs Data Updated Successfully",
          color: "success",
        });
      }else{
        setSnackbarState({
          open: true,
          message: data?.message ? data.message : "Error Updating Logs Status",
          color: "error",
        });
        setOpen(false);
      }
    },
    onError: (error) => {
      setSnackbarState({
        open: true,
        message: "Error Updating Logs Status",
        color: "error",
      });
    },
  });

  function handleClose() {
    setOpen(false);
  }

  const handleOk = () => {
    const data = {
      status: status,
      secret: delSecret
    }
    mutateAsync(data);
  }

  return (
    <>
      {
      !isLoadingData ?
      <>
      <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {"Are You Sure?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Once completed, it cannot be reversed.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="secret"
              name="secret"
              label="Secret Key"
              type="text"
              onChange={(e: any) => setDelSecretKey(e.target.value)}
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { handleClose() }}>Disagree</Button>
            <Button onClick={() => { handleOk() }} autoFocus disabled={delSecret === ''}>
              {
                isLoading ? <CircularProgress size={20} /> : "Agree"
              }
            </Button>
          </DialogActions>
        </Dialog>
      <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            border: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              variant="h3"
              id="tableTitle"
              component="div"
            >
              Logs    
            </Typography>

            <Button
              variant="outlined"
              onClick={() => {
                LogoutUsers();
                navigate("/login");
              }}
              className="btnLogin"
              sx={{ ml: 2, marginLeft: "auto" }}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Typography variant="h4" sx={{marginBottom: "10px"}}>
          Logging enabled creates a log file; if disabled, no logs are generated or saved.
        </Typography>
        <Switch
            checked={status}
            onChange={() => { setOpen(true) }}
          />
      </>
      : 
      <Loading />}
    </>
  )
}

export default LogsStatus